// color palette
$NERO: #1F1F1F;
$NIGHT_RIDER: #303030;
$SILVER: #BABABA;
$GREY: #7C7C7C;
$MORTAR: #535353;
$LINEN: #FCF9F3;
$SELECTIVE_YELLOW: #FFB707;
$SALOME: #FFD885;
$ELECTRIC_INDIGO: #7901F1;
$PERSIAN_INDIGO: #2F0954;

// color palette 01
$BLACK: #000000;
$BLUE_WHALE: #14213D;
$DARK_TANGERINE: #FCA311;
$GAINSBORO: #E4E4E4;
$WHITE: #FFFFFF;
