@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.MuiAlert-filledError {
    background-color: var(--YELLOW) !important;
    color: var(--BLACK) !important;
    font-weight: bold;
}
.MuiAlert-filledWarning .MuiSvgIcon-root {
    fill: var(--BLACK) !important;
}
.MuiAlert-filledWarning {
    background-color: var(--YELLOW) !important;
    color: black !important;
}
.MuiAlert-icon {
    display: none !important;
}
