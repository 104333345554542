@import "../../scss/utilities/colors";

.pageHeaderCard {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    .pageHeading {
        h1 {
            color: $LINEN;
            font-size: 32px;
            line-height: 40px;
            font-weight: 500;
        }
    }
}

.buttonGroup {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .uploadBannerIcon {
        fill: $BLACK!important;
    }
}