@import "../../../../scss/utilities/colors";

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-inline: 60px;

    .mainHeader {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 50%;

        .pageTitle {
            font-size: 48px;
            line-height: 56px;
            font-weight: 700;
            flex: 1 0 auto;
        }

        .commonMatchForm {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            width: 100%;
        }
    }

    .mainBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;

        .addMatchForm {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            width: 100%;

            .container {
                width: 100%;
                background-color: $NIGHT_RIDER;
                border: none;
                border-radius: 20px;
                padding: 28px;
            }
        }
    }
}

.addMatchButtonGroup {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    .formButtons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
    }
}

.matchSummaryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.squadsContainer {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .squadsWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        width: 100%;
    }
}

.oneVsOneWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid $SELECTIVE_YELLOW;
    border-radius: 16px;
    padding: 12px;
    gap: 20px;

    .squadHeader {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 8px;

        .squadDetails {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;

            h3 {
                color: $LINEN;
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
            }

            .squadId {
                font-family: monospace;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .playersList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 16px;
    }
}

.playerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .playerDetails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        .playerImage {
            width: 32px;
            height: 32px;
        }

        .playerName {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            gap: 0;

            .playerNameKey {
                color: $SALOME;
                font-size: 10px;
                line-height: 12px;
                font-weight: 300;
            }

            .playerNameValue {
                color: $LINEN;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }
        }
    }

    .playerCredits {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 0;

        .playerCreditsKey {
            color: $SALOME;
            font-size: 10px;
            line-height: 12px;
            font-weight: 300;
        }

        .playerCreditsValue {
            color: $LINEN;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }
}

.fantasyDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column: span 2;

    .fantasyDetailsForm {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 16px;

        .fantasyTypeWrapper {
            grid-column: span 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            gap: 16px;
            padding-block: 16px;
            border-top: 1px solid $MORTAR;
            border-bottom: 1px solid $MORTAR;

            .switchConfigData {
                grid-column: span 3;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 8px;

                span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;

                    p {
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 400;

                        &:last-child {
                            color: $SELECTIVE_YELLOW;
                        }
                    }
                }
            }
        }

        .checkboxWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            width: 100%;
            grid-column: span 2;
        }

        .matchImageUploadField {
            grid-column: span 2;
            // width: 100%;
        }
    }
}

.contestsContainer {
    grid-column: span 1;
}