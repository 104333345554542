@import "../../../../scss/utilities/colors";

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-inline: 40px;

    .buttonSection {
        width: 100%;
    }

    .headerSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;

        .pageTitle {
            color: $LINEN;
            font-size: 48px;
            line-height: 56px;
            font-weight: 700;
            flex: 1 0 auto;
        }
    }

    .tableSection {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
}

