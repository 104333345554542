@import "../../scss/utilities/colors";

.wrapper {
    border: 1px dashed $LINEN;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 16px;
    position: relative;
    // width: 100%;

    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1 0 auto;

        p {
            margin-block: 0;

            span {
                cursor: pointer;
                margin-left: 4px;
                color: $SELECTIVE_YELLOW;
                transition: all 200ms ease-in-out;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    input {
        width: 100%;
        padding: 40px;
        display: flex;
        flex: 0 1 auto;
        text-align: center!important;
        border-radius: 8px;
        transition: all 200ms ease-in-out;

        &:focus {
            background-color: $NIGHT_RIDER;
        }
    }
}