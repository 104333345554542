@import '../../../Utilities/colors.css';
@import '../../../Utilities/fonts.css';

.grid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 7fr 5fr;
}
.yellow-hover-link {
    color: var(--YELLOW);
}

.login-page {
    font-family: 'Nunito Sans';
}

.login-container {
    background-image: url('../../../Images/starting_page_bg.png');
    background-size: cover;
    background-attachment: fixed;
}
.login-logo-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    align-items: center;
}
.login-card-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 1fr;
}
/* .margin-left-24{
    margin-left: -24px;
  } */

.login-btn {
    background-color: white !important;
    color: black !important;
    border: 0 !important;
    display: flex;
    align-items: center;
}
