@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.MuiFormControl-root {
    width: 100%;
}

.MuiInputLabel-formControl {
    font-size: 14px !important;
    font-family: 'Nunito Sans' !important;
}

.MuiInputLabel-shrink {
    font-family: 'Nunito Sans' !important;
}

.MuiInputBase-input[type='search']:hover,
.MuiInputBase-input[type='search']:focus {
    border-bottom: none !important;
}

.MuiInputBase-input[type='search'] {
    width: 100% !important;
}

.MuiFormLabel-root.Mui-focused {
    font-size: 14px !important;
    font-family: 'Nunito Sans' !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    font-family: 'Nunito Sans' !important;
}

#timer .MuiInputBase-input {
    font-size: 36px !important;
    text-align: center !important;
}

/* #timer .MuiInputBase-input::placeholder {
    text-align: center;
  }
  .MuiInputBase-input input:placeholder-shown {
    text-align: left;
  } */

.MuiInputBase-input.Mui-disabled {
    color: var(--WHITE) !important;
}

.MuiFormLabel-root.Mui-disabled {
    color: var(--Text_Grey) !important;
}

.MuiTypography-body1 {
    font-size: 14px !important;
}
