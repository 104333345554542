// @import "../../../../../scss/utilities/colors";
@import '../../../../scss/utilities/colors';

.container {
    background-color: $NERO;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
            color: $LINEN;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        .closeButton {
            color: $LINEN !important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $NIGHT_RIDER;
    }

    .body {
        width: 100%;
    }
}
