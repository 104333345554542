@import "../../scss/utilities/colors";

.header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    // background-color: #8368F4;
    // background-color: #00A61B;
    // height: 60px;
    padding-block: 12px;
    z-index: 10;

    &.fcHeader {
        background: linear-gradient(to top, $ELECTRIC_INDIGO, $PERSIAN_INDIGO, $BLACK);
    }

    &.jadeHeader {
        background-color: #00A61B;
    }

    &.unicornHeader {
        background-color: #FF0000;
    }

    &.pieHeader {
        background-color: mediumslateblue;
    }

    .navbar {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 20px;
        width: 100%;
        height: 100%;
        padding-inline: 20px;

        .clientWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 8px;
            width: 100%;

            .clientSelectField {
                width: 40%;
            }

            .fcLogo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 160px;

                img {
                    width: 100%;
                }
            }

            .jadeLogo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 120px;

                img {
                    width: 100%;
                }
            }

            .unicornLogo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 40px;

                img {
                    width: 100%;
                }
            }
        }

        .navLinks {
            list-style: none;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 8px;
        }
    }
}

.dropdownWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dropdownButton {
        background: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border: none;
        transition: all 200ms ease-in-out;

        .startButtonIcon {
            width: 16px;
            height: 16px;
            fill: $LINEN;
        }

        p {
            color: $LINEN;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.8px;
        }

        .endButtonIcon {
            width: 16px;
            height: 16px;
            fill: $LINEN;
        }
    }

    .dropdownContent {
        position: absolute;
        top: 112%;
        width: 100%;
        background-color: $NERO;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-radius: 12px;
        overflow: hidden;

        .contentItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: transparent;
            text-align: center;
            color: $LINEN;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.4px;
            border: none;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            padding: 12px 16px;
            transition: all 200ms ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $SALOME;
                color: $NERO;
                border-radius: 12px;
            }
        }
    }

    .dropdownContent.dropdownContentActive {
        display: flex;
    }

    &:hover {
        .dropdownButton {

            .startButtonIcon {
                fill: $SELECTIVE_YELLOW;
            }

            p {
                color: $SELECTIVE_YELLOW;
            }

            .endButtonIcon {
                fill: $SELECTIVE_YELLOW;
            }
        }
    }
}