@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.MuiOutlinedInput-adornedStart {
    background-color: var(--Navbar-Grey);
    font-family: 'Nunito Sans' !important;
    border: none !important;
    border-radius: 24px;
    width: 100%;
    padding: 2px !important;
}

.MuiOutlinedInput-inputAdornedStart {
    padding: 7px 5px !important;
}

.MuiInputBase-root
    .MuiOutlinedInput-root
    .Mui-focused
    .Mui-focused
    .MuiInputBase-formControl
    .MuiInputBase-adornedStart
    .MuiOutlinedInput-adornedStart {
    border: none !important;
    outline: none !important;
}
/* .MuiIconButton-root{
    padding: 7px 5px !important;
} */
.MuiIconButton-label img {
    width: 18px;
}
