@import "../../../../../scss/utilities/colors";

.container {
    background-color: $NERO;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    height: 100%;
    width: 60%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
            color: $LINEN;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        .closeButton {
            color: $LINEN!important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $NIGHT_RIDER;
    }

    .body {
        width: 100%;
        max-height: 440px;

        .addEditCommonMatchForm {
            width: 100%;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 24px;

            .tabsContainer {
                width: 100%;
                // border-bottom: 1px solid $LINEN;
            }

            .formButtonGroup {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                gap: 8px;

                .submitButton, .resetButton {
                    flex-grow: 0;
                }
            }
        }
    }
}

.matchSummarySection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 100%;
    gap: 16px;
    background: $NIGHT_RIDER;
    padding: 16px;
    border-radius: 16px;
    overflow-y: scroll;
}

.squadsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
    background: $NIGHT_RIDER;
    padding: 16px;
    border-radius: 16px;
    overflow-y: scroll;

    .addSquadWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 16px;

        .addSquadId {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
        }
    }

    .squadsList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 16px;

        .oneVsOneWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            border: 1px solid $SELECTIVE_YELLOW;
            border-radius: 16px;
            padding: 12px;
            gap: 20px;
        
            .squadHeader {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                gap: 8px;
        
                .squadDetails {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;
        
                    h3 {
                        color: $LINEN;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                    }
        
                    .squadId {
                        font-family: monospace;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                .squadButtonGroup {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;
                }
            }
        
            .playersList {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                gap: 16px;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: $NIGHT_RIDER;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $SALOME;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $SELECTIVE_YELLOW;
    }
}

.playerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .playerDetails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;

        .playerImage {
            width: 32px;
            height: 32px;
        }

        .playerName {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            gap: 0;

            .playerNameKey {
                color: $SALOME;
                font-size: 10px;
                line-height: 12px;
                font-weight: 300;
            }

            .playerNameValue {
                color: $LINEN;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }
        }
    }

    .playerActions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
    }

    .playerAttributes {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        width: 100%;

        .playerAttribute {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 0;
    
            .playerAttributeKey {
                color: $SALOME;
                font-size: 10px;
                line-height: 12px;
                font-weight: 300;
            }
    
            .playerAttributeValue {
                color: $LINEN;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }
        }
    }
}