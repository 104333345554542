@import "../../../../scss/utilities/colors";

.container {
    background-color: $NERO;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    // height: 100%;
    width: 40%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
            color: $LINEN;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        .closeButton {
            color: $LINEN!important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $NIGHT_RIDER;
    }

    .body {
        width: 100%;
        max-height: 440px;

        .form {
            width: 100%;
            max-height: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;

            .fullWidthField {
                grid-column: span 2;
            }

            .switchFeesWrapper {
                grid-column: span 2;
                padding-block: 24px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;
                width: 100%;
                border-top: 1px solid $GREY;
                border-bottom: 1px solid $GREY;
            }

            .formButtonGroup {
                grid-column: span 2;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                gap: 8px;

                .submitButton, .resetButton {
                    flex-grow: 0;
                }
            }
        }
    }
}