@import "../../../../../scss/utilities/colors";

.container {
    background-color: $NERO;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
            color: $LINEN;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        .closeButton {
            color: $LINEN!important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $NIGHT_RIDER;
    }

    .body {
        width: 100%;

        .confirmationTextWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin: 8px 0px 20px 0px;

            p {
                color: $LINEN;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }
        }

        .buttonGroup {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 8px;
        }
    }
}