@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.navbar {
    background-color: var(--Navbar-Grey);
    font-family: 'Nunito Sans' !important;
    padding: 0 16px;
    height: 60px;
}

.navbar ul,
.searchbar ul {
    display: flex;
    align-items: center;
    padding: 0 !important;
    justify-content: flex-start;
}
.navbar ul li,
.searchbar ul li {
    list-style-type: none;
    color: var(--Text_Grey);
    font-size: 14px;
    font-family: 'Nunito Sans';
    cursor: pointer !important;
    padding-right: 35px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.menu-title {
    color: var(--WHITE) !important;
    font-size: 16px !important;
    font-weight: 400;
    padding-left: 30px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.nav-grid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 9fr 1.25fr;
    align-content: center;
    grid-column-gap: 10px !important;
    height: 100%;
}

.active {
    color: var(--YELLOW) !important;
}

.disp-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.MuiMenu-paper {
    background-color: var(--GREY) !important;
    color: var(--WHITE) !important;
    font-family: 'Nunito Sans' !important;
}
.MuiMenu-list,
.MuiMenuItem-root {
    font-size: 14px !important;
    font-family: 'Nunito Sans' !important;
}
