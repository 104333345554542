@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.button,
.cancel-button {
    color: var(--YELLOW);
    border: 2px solid var(--YELLOW);
    padding: 10px 16px;
    border-radius: 24px;
    font-size: 14px;
    font-family: 'Nunito Sans Bold';
    background-color: transparent;
    width: max-content;
}

.cancel-button {
    color: var(--Text_Grey);
    border: 2px solid var(--Text_Grey);
}

.button.button_sm {
    padding: 5px 8px;
    font-size: 12px;
    border-width: 1px;
    align-self: center;
}
