.date-time-picker {
    position: relative;
}
.date-time-picker .date-time-picker-input {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 1px;
}
