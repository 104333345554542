@import '../../../Utilities/colors.css';
@import '../../../Utilities/fonts.css';

.table-cell {
    padding: 5px;
    border: 1px solid var(--WHITE);
    border-bottom: 0px;
}
.new {
    background-color: unset !important;
    padding-left: 5px;
    padding-bottom: 0px;
    font-size: 14px !important;
    font-family: 'Nunito Sans' !important;
}
.bottom {
    border-top: 1px solid var(--WHITE);
}

a {
    text-decoration: none;
    color: var(--YELLOW);
}
