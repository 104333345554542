@import "../../scss/utilities/colors";

// to be removed when globally set
// * {
//     padding: 0;
//     margin: 0;
//     font-family: "Nunito Sans", sans-serif;
//     font-size: 10px;
//     box-sizing: border-box;
//     scroll-behavior: smooth;
// }

// to be removed when globally set
// body {
//     background-color: $NERO;
// }

.layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
}

.main {
    height: calc(100vh - 60px);
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}