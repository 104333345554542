.summaryTab {
    width: 100%;
}

.squadsAndPlayersTab {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-block: 16px;

    .addSquadAndPlayerSection {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 4fr;
        column-gap: 16px;

        .addSquadButtonGroup {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .addSquadButton {
                margin-right: 8px;
            }
        }
    }

    .rosterSection {
        width: 100%;
        padding: 12px;
        margin-block: 12px;

        .roster {
            margin-bottom: 12px;
            background: #444;
            border-radius: 12px;
            padding: 12px;

            .squadWrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
        
                .squadDetails {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
        
                    .header {
                        color: #AEB1BB;
                        font-size: 12px;
                        font-family: "Nunito Sans";
                        margin-block: 0;
                    }

                    .squadName {
                        color: #FFF;
                        font-size: 16px;
                        font-family: "Nunito Sans";
                        margin-block: 0;
                    }
                }

                .buttonGroup {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 16px;

                    .squadIconButton {
                        height: 16px;
                        width: 16px;

                        .icon {
                            fill: #FFF!important;

                            &:hover {
                                fill: #FFD885!important;
                            }
                        }
                    }
                }

                .squadOddsInputField {
                    width: 30%;
                }
            }

            .squadLineupDivider {
                display: block;
                background-color: #000;
                width: 100%;
                height: 1px;
                margin-block: 12px;
            }

            .lineupWrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .player {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    padding-block: 12px;

                    .playerDetail {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        width: 50%;

                        .header {
                            color: #AEB1BB;
                            font-size: 12px;
                            line-height: 20px;
                            font-family: "Nunito Sans";
                            margin-block: 0;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            .playerImg {
                                margin-right: 8px;
                            }

                            .playerDescription {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 100%;

                                .playerName {
                                    cursor: pointer;
                                    color: #FFD885;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-family: "Nunito Sans";
                                    margin-block: 0;
                                    margin-right: 4px;
                                    transition: all 200ms ease-in-out;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                .playerDetails {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width: 100%;

                                    .playerPosition {
                                        color: #fff;
                                        font-family: "Nunito Sans";
                                        font-size: 12px;
                                        line-height: 20px;
                                        margin-block: 0;
                                    }

                                    .playerFreezeStatus {
                                        color: #fff;
                                        font-family: "Nunito Sans";
                                        font-size: 12px;
                                        line-height: 20px;
                                        margin-block: 0;
                                    }
                                }
                            }
                        }
                    }

                    .lineupDropdownField {
                        width: 30%;
                    }

                    .lineupOrderInputField {
                        width: 20%;
                    }

                    .playerCredit {
                        width: 10%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: flex-start;

                        .header {
                            color: #AEB1BB;
                            font-size: 12px;
                            font-family: "Nunito Sans";
                            margin-block: 0;
                        }

                        .credit {
                            color: #FFF;
                            font-size: 16px;
                            font-family: "Nunito Sans";
                            margin-block: 0;
                        }
                    }
                }
            }
        }
    }
}

