@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.ReactTable .-pagination .-btn[disabled]:hover {
    background-color: unset !important;
}
.table-div {
    overflow-x: auto;
    background-color: var(--GREY);
}

.ReactTable .-pagination .-btn {
    margin: auto !important;
    width: auto !important;
    padding: 8px 16px !important;
}

.ReactTable {
    font-family: 'Nunito Sans';
    background: var(--BLACK);
    border-top: 1px solid var(--BORDER_GREY);
}
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead .rt-th {
    text-align: left;
}
.ReactTable .rt-thead .rt-th {
    color: var(--Text_Grey);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: var(--BLACK);
}
.ReactTable .rt-thead.-header {
    box-shadow: none;
}
.ReactTable .rt-tbody .rt-tr-group {
    border: 0;
}
.ReactTable .rt-tr {
    display: flex;
}
.rt-table {
    border-top: solid 1px var(--BORDER-GREY) !important;
    border-bottom: solid 1px var(--BORDER-GREY) !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-tbody .rt-td {
    border: 0;
}
.ReactTable .rt-tbody .rt-td {
    font-size: 12px;
    padding: 10px;
    line-height: 25px;
}

.ReactTable .rt-thead .rt-th {
    padding: 10px;
    min-width: 50px;
}

.ReactTable .rt-tbody .rt-tr:not(.-padRow):hover {
    background: var(--Navbar-Grey) !important;
}

.ReactTable .rt-tbody .rt-tr-group:nth-child(even) {
    background-color: var(--GREY);
}
.ReactTable .rt-tbody .rt-tr-group:nth-child(odd) {
    background: var(--Navbar-Grey);
}
.ReactTable .rt-tbody .rt-tr:not(.-padRow):hover .hide-button {
    display: inline-block;
}

.ReactTable .rt-th,
.ReactTable .period.rt-td {
    white-space: unset !important;
    line-height: 15px !important;
}
.ReactTable .rt-td {
    align-self: center;
}

.-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--BLACK);
    padding: 16px 0;
    width: 45%;
    margin: auto;
    position: relative;
}

.rt-noData,
.-loading {
    display: none;
}

.-center,
.-pageJump,
.-pageInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.-pageJump input,
.select-wrap.-pageSizeOptions select {
    background-color: var(--GREY);
    color: var(--WHITE);
    width: 65px !important;
    margin: 0 10px;
    border: none !important;
    text-align: center;
    border-radius: 14px;
    padding: 10px;
}
.select-wrap.-pageSizeOptions select {
    width: 100px !important;
}
.-totalPages {
    margin: 0 5px;
}

.select-wrap.-pageSizeOptions select option:hover {
    background-color: var(--BORDER-GREY);
}

.ReactTable .-pagination .-btn {
    color: var(--YELLOW);
    border: 2px solid var(--YELLOW);
    padding: 12px 16px;
    border-radius: 24px;
    font-size: 12px;
    font-family: 'Nunito Sans Bold';
    background-color: transparent;
    margin: 0 16px !important;
}

/* TABLE ROW SELECTED */
.select-row,
.selected-row {
    width: 21px;
    height: 21px;
    border: 2px solid var(--Text_Grey);
    padding: 2px;
    cursor: pointer;
}

.selected-row {
    border: 2px solid var(--YELLOW);
}
.rt-tr,
.rt-tbody {
    min-height: max-content !important;
    min-width: max-content !important;
}
.rt-table {
    min-width: fit-content;
}

option {
    padding: 5px;
}

/*project list table pagination*/

.ReactTable .-pagination .-btn:hover {
    background-color: transparent !important;
}

.ReactTable .-pagination .-btn[disabled] {
    color: #999 !important;
}

.ReactTable .-pagination .-btn {
    margin: 0 !important;
    border: none !important;
    font-size: 0 !important;
}

.ReactTable .-pagination .-next .-btn {
    padding: 12px 50px 12px 10px !important;
}

.ReactTable .-pagination .-previous .-btn {
    float: right;
    padding: 0px 10px 0 50px !important;
}

.ReactTable .-pagination .-previous .-btn:before {
    content: url('../../Images/prev_team.png');
    position: absolute;
    bottom: 14px;
    margin-left: 20px;
}

.ReactTable .-pagination .-previous .-btn[disabled]:before {
    content: url('../../Images/prev_team.png');
    position: absolute;
    bottom: 14px;
    margin-left: 20px;
}

.ReactTable .-pagination .-next .-btn[disabled]:after {
    content: url('../../Images/next_team.png');
    position: absolute;
    bottom: 14px;
    margin-left: -70px;
}

.ReactTable .-pagination .-next .-btn:after {
    content: url('../../Images/next_team.png');
    position: absolute;
    bottom: 14px;
    margin-left: -70px;
}

.ReactTable .-pagination input,
select {
    font-weight: bold !important;
}

.ReactTable .-pagination .-center {
    flex: 1 !important;
    justify-content: center !important;
}

/*****************************/

@media (min-width: 1400px) {
    .ReactTable .-pagination .-previous .-btn:before {
        margin-left: 5rem;
    }

    .ReactTable .-pagination .-previous .-btn[disabled]:before {
        margin-left: 5rem;
    }

    .ReactTable .-pagination .-next .-btn[disabled]:after {
        bottom: 15px;
        margin-left: -8rem;
    }

    .ReactTable .-pagination .-next .-btn:after {
        bottom: 15px;
        margin-left: -8rem;
    }
}

.pagination {
    padding: 10px 0;
    background-color: var(--GREY);
    width: 100%;
}

.pageNumber {
    padding: 5px 20px;
    background-color: var(--BORDER-GREY);
    margin: 5px 16px 5px 5px;
    border-radius: 10px;
}
