@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

#modal .MuiCardContent-root {
    background-color: var(--MODAL) !important;
}
.MuiCardContent-root,
#inner-card .MuiCardContent-root {
    background-color: var(--GREY) !important;
    color: var(--WHITE) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    font-family: 'Nunito Sans';
}

.card-grid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 6fr 6fr;
    align-items: center;
    grid-column-gap: 16px !important;
}
.card-fullgrid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 12fr;
    align-content: center;
    grid-column-gap: 16px !important;
}
.card-fullgrid-container .card-grid-container {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--BLACK);
}
.MuiTabs-root,
.card-grid-container {
    background-color: transparent !important;
    box-shadow: none !important;
}

.MuiCard-root {
    border-radius: 0 !important;
}
.full-grid {
    grid-area: 'fullGrid';
}
.grid-item {
    grid-area: 'halfGrid';
}

.grid-col-1-snap-2 {
    grid-column: 1 / span 2;
}

.grid-8-4-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 8fr 4fr;
    align-content: center;
    grid-column-gap: 16px !important;
}

.grid-2-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 6fr 6fr;
    align-items: center;
    grid-column-gap: 16px !important;
}

.grid-3-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    align-content: center;
    grid-column-gap: 16px !important;
}

.grid-4-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
    align-content: center;
    grid-column-gap: 16px !important;
}

.modal-big-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    align-content: center;
    grid-column-gap: 16px !important;
}
.four-column-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-content: center;
    grid-column-gap: 16px !important;
}
.notification-textarea textarea {
    min-height: auto !important;
}

.align-content {
    align-content: start;
}

.grid-3-container-and-delete {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 3.5fr 3.5fr 3.5fr 1.5fr;
    align-items: center;
    grid-column-gap: 16px !important;
}
