.create-expert-modal .list {
    list-style: none;
    padding-left: 0;
}

.create-expert-modal .list-item {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-expert-modal .heading-desc {
    font-size: 0.7em;
    margin: 0;
}
