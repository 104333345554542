@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.auto-search.hide:focus {
    display: block;
}

/* .MuiSvgIcon-root {
    fill: var(--WHITE) !important;
} */

.MuiSvgIcon-colorPrimary {
    fill: var(--YELLOW) !important;
}

.MuiIconButton-label {
    color: var(--WHITE);
}

.MuiSvgIcon-fontSizeSmall {
    color: var(--WHITE);
}

.MuiAutocomplete-option,
.MuiAutocomplete-listbox,
.MuiAutocomplete-option[data-focus='true'] {
    background-color: var(--Navbar-Grey) !important;
}

.red-color {
    color: var(--RED) !important;
    fill: var(--RED) !important;
}

.green-color {
    color: var(--GREEN) !important;
    fill: var(--GREEN) !important;
}
