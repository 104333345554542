@import "../../../../scss/utilities/colors";
.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-inline: 40px;

    .buttonSection {
        width: 100%;
    }

    .headerSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;

        .pageTitle {
            color: $LINEN;
            font-size: 48px;
            line-height: 56px;
            font-weight: 700;
            flex: 1 0 auto;
        }
    }

    .filtersSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .filtersContainer {
            background-color: $NERO;
            border-radius: 20px;
            padding: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;

            .header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;

                .title {
                    h3 {
                        color: $LINEN;
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: 600;
                        flex: 1 0 auto;
                    }
                }
                .resetButton {
                    flex-grow: 0;
                }
            }
            .filterFields {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 8px;
                width: 100%;
            }
        }
    }

    .tableSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

        .tableContainer {
            background-color: $NERO;
            border-radius: 20px;
            padding: 20px;
            max-width: 100%;
        }

        .tablePaginationContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
}

.table {
    table-layout: fixed;
    width: 100%;
    overflow-x: scroll;
}

.tableHead {
    width: 100%;

    .tableHeadRow {
        width: 100%;
        
        &:hover {
            background-color: transparent!important;
        }

        .tableHeadCell {
            color: $LINEN;
            font-family: "Sora", sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding: 16px 0;
            border-bottom: 0;
        }
    }
}

.tableBody {
    width: 100%;

    .tableBodyRow {
        width: 100%;
        border-bottom: 1px solid $MORTAR;

        &:hover {
            background-color: $NIGHT_RIDER!important;

            .tableBodyCell {
                color: $LINEN;
            }
        } 
        
        .tableBodyCell {
            color: $GREY;
            font-family: "Sora", sans-serif;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            padding: 8px 0;
            border-bottom: 0;
        }

        .bodyIdCell {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            .actionButtonGroup {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 4px;
                .actionButton {
                    color: $GREY;
                }
            }
        }
    }
}

.paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .pageNumber {
        background-color: $NERO;
        padding: 8px 20px;
        border-radius: 8px;

        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
    }
}

.width100 {
    width: 100px;
}

.width200 {
    width: 200px;
}

.width300 {
    width: 300px;
}

.width400 {
    width: 400px;
}