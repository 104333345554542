:root {
    --YELLOW: #ffd885;
    --BLACK: #000000;
    --WHITE: #ffffff;
    --GREY: #222222;
    --Navbar-Grey: #333333;
    --Text_Grey: #aeb1bb;
    --BORDER-GREY: #5f6477;
    --RED: #fa8a85;
    --MODAL: #1b202d;
    --IMGMODAL: #999999;
    --GREEN: #4ff7ca;
}
