@import "../../../../../scss/utilities/colors";

.container {
    background-color: $NERO;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
            color: $LINEN;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
        }

        .closeButton {
            color: $LINEN!important;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $NIGHT_RIDER;
    }

    .body {
        width: 100%;

        .uploadForm {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 24px;

            .formButtonGroup {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                gap: 8px;
            }
        }

        .responseText {
            color: $SELECTIVE_YELLOW;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            margin-top: -16px;
            padding: 0;
        }
    }
}