@import "../../scss/utilities/colors";

.layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 60px;
    height: 60px;
    max-height: 60px;
    z-index: 10;
    background-color: $BLACK;

    .nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding-block: 20px;
        height: 100%;
        // width: 100%;

        .backButton {
            color: $LINEN!important;
            border: 2px solid $LINEN;
            
            .backButtonIcon {
                color: $LINEN;
                width: 20px;
                height: 20px;

                &:hover {
                    color: $SELECTIVE_YELLOW;
                }
            }

            &:hover {
                background-color: transparent;
                color: $SELECTIVE_YELLOW;
                border: 2px solid $SELECTIVE_YELLOW;
            }
        }

        .clientSelectField {
            width: 20%;
        }
    }
}

.main {
    height: calc(100vh - 60px);
    width: 100%;
    margin-block: 80px;
    padding-inline: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: none;
    gap: 8px;
}