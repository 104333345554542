@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.searchBar-grid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 3fr 1fr 4fr 4fr;
    align-content: center;
    grid-column-gap: 16px !important;
}
.searchBar-grid-container ul {
    margin: 0 !important;
    width: 100%;
}
.searchbar-center-header {
    font-size: 15px;
    margin: 10px 0;
    font-family: 'Nunito Sans Bold';
}
.searchbar {
    /*min-height: 50px;*/
}
