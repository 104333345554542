.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    max-height: 560px;
    background: #fff;
    box-shadow: 24;
    border-radius: 16px;
    padding: 16px;
}