.MuiSelect-selectMenu {
    text-align: left;
}

.MuiFormControl-root {
    margin: 0 !important;
}

.MuiMenu-list {
    background-color: var(--Navbar-Grey) !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}
