@font-face {
    font-family: 'Nunito Sans';
    src: url('./fonts/NunitoSans-Regular.ttf');
}

@font-face {
    font-family: 'Nunito Sans Bold';
    src: url('./fonts/NunitoSans-Bold.ttf');
}

@font-face {
    font-family: 'Bungee';
    src: url('./fonts/BungeeInline-Regular.ttf');
}
