.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    width: 100%;
    max-width: 80%;
    overflow: hidden;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #000000;

        .modalHeading {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin-block: 0;
        }

        .closeButton {
            width: 20px;
            height: 20px;

            .closeIcon {
                fill: #000000!important;
            }
        }
    }

    .body {
        width: 100%;
        overflow: auto;

        .aggregrateFields {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-block: 8px;

            p {
                margin: 0;
                color: #000000;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 12px;
            }
        }

        .tableContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            max-height: 800px;
            overflow: scroll;
            margin-block: 12px;

            .resultTable {
                border-collapse: collapse!important;
                border: 1px solid #000000;
            }

            &::-webkit-scrollbar {
                width: 8px!important;
                height: 8px!important;
                border-radius: 12px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 12px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 12px;
                background: rgba($color: #000000, $alpha: 0.5);
            }
        }
    }
}

.tableHead {
    width: 100%;

    tr {
        width: 100%;
        border: 1px solid #000000;
        background: rgba($color: #000000, $alpha: 0.50);

        &:hover {
            background: rgba($color: #000000, $alpha: 0.50);
        }

        th {
            color: #000000;
            border: 1px solid #000000;
            padding: 12px 16px!important;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            text-align: center;
            margin: 0;
        }
    }
}

.tableBody {
    width: 100%;

    tr {
        width: 100%;
        border: 1px solid #000000;

        &:hover {
            background: rgba($color: #000000, $alpha: 0.20);
        }

        td {
            border: 1px solid #000000!important;
            padding: 8px 12px!important;

            p {
                color: #000000;
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                text-align: center;
                margin: 0;
            }
        }
    }
}
