.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper div {
    display: block;
}

.react-datepicker-popper {
    z-index: 3;
}

.react-datepicker__close-icon::after {
    background-color: var(--YELLOW);
}

.mt-20 {
    margin-top: 20px !important;
}
