@import '../../Utilities/colors.css';
@import '../../Utilities/fonts.css';

.commonnav-grid-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    align-content: center;
    grid-column-gap: 10px !important;
    padding: 5px 0;
}
.commonnav-grid-container .grid-item {
    min-height: fit-content !important;
}
.left-header {
    justify-content: flex-start !important;
}
.center-heading {
    color: var(--WHITE) !important;
    font-family: 'Nunito Sans Bold' !important;
    font-size: 14px !important;
}
.live {
    color: var(--RED);
    border: 1px solid var(--RED);
    font-size: 16px;
    padding: 7px;
}
.live-time {
    color: var(--WHITE) !important;
    font-size: 16px !important;
}

.commonNavbar {
    min-height: 50px;
}
