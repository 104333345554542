@import "./utilities/colors";

* {
    padding: 0;
    margin: 0;
    font-family: "Sora", sans-serif;
    font-size: 12px;
    outline: none!important;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    background-color: $BLACK;
    color: $LINEN;
}